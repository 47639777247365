<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加网点</en-button>
    </template>
    <flex-box>
      <template #default="{ height }">
        <form-query :method="table.get" :model="form.data">
          <en-form-item label="编号">
            <en-input v-model="form.data.goodsSerialNo"></en-input>
          </en-form-item>
        </form-query>

        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="A单类型" prop="name"></en-table-column>
          <en-table-column label="配件类型">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['ChargingStandardRuleDto'] }">{{
              row.goods.map((item) => `${item.type?.message}`).join(',')
            }}</template>
          </en-table-column>
          <en-table-column label="包含配件">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['ChargingStandardRuleDto'] }">{{
              row.goods.map((item) => `${item.storageGoodsSpecification?.goods?.serialNo}(${item.storageGoodsSpecification?.goods?.name})`).join(',')
            }}</template>
          </en-table-column>
          <en-table-column label="操作">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['ChargingStandardRuleDto'] }">
              <en-button @click="table.edit.click(row)" text>查看</en-button>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible" title="新增包含配件">
    <en-form :model="detail.form.data" :ref="setRef('detailForm')">
      <en-form-item label="A单类型">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="包含配件" props="goods">
        <select-maintain
          v-model="detail.form.data.goods"
          :ajax="{
            action: 'GET /enospray/common/goods',
            params: (params, value) => {
              params.payload = { name: value }
            }
          }"
          :props="{ label: 'storageGoodsSpecification.goods.name', value: '' }"
          value-key="id"
          clearable
          multiple
          class="w-full"
        >
        </select-maintain>
      </en-form-item>
    </en-form>
    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },
      form: {
        data: {
          goodsSerialNo: ''
        },
        init() {
          this.form.data = {
            goodsSerialNo: ''
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/common/chargingstandardrule',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        edit: {
          async click(row: EnosprayCommonDefinitions['ChargingStandardRuleDto']) {
            this.detail.form.init()
            // this.detail.form.init()
            this.detail.form.data.id = row.id
            await this.detail.form.get()

            this.detail.visible = true
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            data: {
              id: '',
              goods: [],
              name: ''
            },
            ajax: {
              get: {
                action: 'GET /enospray/common/chargingstandardrule/:chargingStandardRuleId',
                data: 'object',
                init: true,
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enospray/common/chargingstandardrule',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enospray/common/chargingstandardrule',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()

  }
})
</script>
